import "./Nav.scss";
import Change from "./Lang";

const Nav = () => {
  return (
    <div className="navbar mb-40 shadow-xl rounded-box bg-gradient-to-r from-blue-500 dark:from-purple-600 to-blue-800 dark:to-purple-900 text-neutral-content" id="navbar">
      <div className="navbar-start">
      <a className="btn btn-ghost normal-case text-xl">PicoAoi</a>
      </div>
      {/**<div className="navbar-center"></div>**/}
      <div className="navbar-end">
        <Change />
      </div>
    </div>
  )
}

export default Nav;