import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Props {
    wy: number;
    ch: number;
}

const Top: React.FC<Props> = (prop) => {
    const { wy, ch } = prop;
    const visible = (wy > ch / 2) ? 'visible' : 'hidden';
    const val = (wy > ch / 2) ? '1' : '0';
    const toLocation = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
      };
    return (
        <div className='backTop right-10 bottom-[4rem] mb:bottom-14 fixed z-20' id="backTop" style={{visibility:visible, opacity:val}}>
            <button className="btn btn-square btn-outline text-black dark:text-white" onClick={toLocation}>
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
        </div>
    );
}
export default Top;