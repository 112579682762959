import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "lang": "Language",
            "hi": "Hi, I am",
            "glad": "Glad to see you.",
            "aboutMe": "About me",
            "jobs": "Student · Developer · Former office worker",
            "likes": "Front-end · Information Visualization · Machine learning",
            "contriF": "Collaborator of",
            "contriL": "",
            "latest": "LATEST POST",
            "contact": "CONTACT",
            "powered": "A React Application",
            "workL":"If you want to know more, please click ",
            "workR":"",
            "here":"here",
            "close":"Close",
            "borderEN":"bordered",
            "borderCN":"hover-bordered",
            "borderJA":"hover-bordered",
        }
    },
    ja: {
        translation: {
            "lang": "言語",
            "hi": "初めましで、",
            "glad": "です。",
            "aboutMe": "私について",
            "jobs": "学生 · 開発者 · 元会社員",
            "likes": "フロントエンド · データ可視化 · 機械学習",
            "contriF": "",
            "contriL": "のコラボレーター",
            "latest": "最新記事",
            "contact": "お問い合わせ",
            "powered": "A React Application",
            "workL":"もしも詳しく知りたいなら、",
            "workR":"をクリックしてください",
            "here":"こちら",
            "close":"閉じる",
            "borderEN":"hover-bordered",
            "borderCN":"hover-bordered",
            "borderJA":"bordered",
        }
    },
    zh: {
        translation: {
            "lang": "语言",
            "hi": "你好，我是",
            "glad": "很高兴见到你。",
            "aboutMe": "关于我",
            "jobs": "学生 · 开发者 · 前互联网民工",
            "likes": "前端开发 · 数据可视化 · 机器学习",
            "contriF": "",
            "contriL": "的Collaborator",
            "latest": "最新文章",
            "contact": "联系我",
            "powered": "A React Application",
            "workL":"如果你想了解更多我参与的项目，请点击",
            "workR":"",
            "here":"这里",
            "close":"关闭",
            "borderEN":"hover-bordered",
            "borderCN":"bordered",
            "borderJA":"hover-bordered",
        }
    }
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;