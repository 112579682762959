import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faReact, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import smoothscroll from 'smoothscroll-polyfill';

import Nav from './Nav';
import './App.scss';
import Top from './Top';

interface postArray {
  key: number,
  title: string,
  _url: string,
  summary: string
}

const App = () => {
  smoothscroll.polyfill();
  const { t } = useTranslation();
  document.title = 'PicoAoi(@robert1chi)';
  const [data, setData] = useState([{ key: 1, title: "Loading", _url: "", summary: "Loading" },])
  useEffect(() => {

    const pageArr: postArray[] = []
    const apiUrl = 'https://blog.picoaoi.com/wp-json/wp/v2/posts';
    fetch(apiUrl, {
      mode: "cors",

    })
      .then((response: any) => {
        response.json()
          .then((response: any) => {
            for (let i = 0; i < 5; i++) {
              pageArr.push({
                key: i,
                title: response[i].title.rendered,
                _url: `https://blog.picoaoi.com/?p=${response[i].id}`,
                summary: response[i].excerpt.rendered,
              });
            }
            setData(pageArr)
          })

      })
      .catch((error: any) => {
        console.log(error)
      })
  }, [])
  const [value, setValue] = useState(0);
  const toLocation = (location: string) => {
    const dom = document.querySelector(`${location}`)
    if (dom) dom.scrollIntoView({ behavior: "smooth" });
  };
  const [clientHeight, setClientHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  useEffect(
    () => {
      setClientHeight(document.documentElement.clientHeight);
      setScrollHeight(document.documentElement.scrollHeight);
      //listen
      window.addEventListener("scroll", handleScoll);
    },
    []
  );
  const handleScoll = () => {
    setValue(window.scrollY);
  };
  let progressMax = scrollHeight - clientHeight;

  return (
    <div id='home'>
      <div className="container">
        <main className="main">
          <div className="artboard phone" id="pro">
            <progress
              className="progress dark:progress-primary"
              value={value}
              max={progressMax}
              id="top"
            />
          </div>

          <div className="firstPage">
            <Nav />

            <div id="title">
              <h1 className="title">
                <p className="font-serif text-2xl">
                  {t('hi')}
                  <br />
                  <div className="mockup-code font-mono mt-4 mb-4 float-left text-xl">
                    <pre data-prefix="$">
                      <code className="mr-6">PicoAoi<a id="bli">&#8197;</a></code>
                    </pre>
                  </div>
                  <br />
                  {t('glad')}
                </p>
              </h1>
            </div>
            <div id="social" className="grid grid-cols-2 gap-4 w-30 font-semibold">
              <div id="blog" className="w-15 h-5 flex text-blue-500 dark:text-violet-400">
                <FontAwesomeIcon className="w-5 h-5 flex-none mr-2" icon={faEdit} />
                <p className="h-5 flex-initial flex justify-center items-center"><a id="blogLine" href="https://blog.picoaoi.com">Blog</a></p>
              </div>
              <div id="github" className="w-15 h-5 flex text-blue-500 dark:text-violet-400">
                <FontAwesomeIcon className="w-5 h-5 flex-none mr-2" icon={faGithub} />
                <p className="h-5 flex-initial flex justify-center items-center"><a id="githubLine" href="https://github.com/robert1chi">GitHub</a></p>
              </div>
            </div>
            <div
              className="contain"
              onClick={() => {
                toLocation("#secondPage");
              }}
            >
              <div className="chevron"></div>
              <div className="chevron"></div>
              <div className="chevron"></div>
            </div>
          </div>
          <div id="secondPage">
            <div className="flex" id="box">
              <div className="card shadow-2xl lg:card-side dark:bg-base-200" id="right">
                <div className="avatar">
                  <div className="m-0 rounded-full w-24 h-24">
                    <img src="https://avatars.githubusercontent.com/u/40431036?s=400&u=f7232735cb72d33aeaccad41be051963b3a39ece&v=4" alt="GitHub Figure"/>
                  </div>
                </div>
                <div className="card-body col-span-2">
                  <div id="right">
                    <h2 className="text-xl card-title">{t("aboutMe")}</h2>
                    <p><FontAwesomeIcon icon={faMapMarkerAlt } /> Tokyo, Japan</p>
                    <p>
                      zh-CN · en-US · ja-JP
                      <br />
                      {t('jobs')}
                      <br />
                      {t('likes')}
                    </p>
                    <p className="mt-1">
                      {t('contriF')} <a className="text-blue-500 dark:text-violet-400" id="sakura" href="https://github.com/mirai-mamori/Sakurairo">Sakurairo</a>{t('contriL')}
                      <br />
                      {t('workL')}<a className="text-blue-500 dark:text-violet-400" id="sakura" href="https://kaguyasama.moe" >{t('here')}</a>{t('workR')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="thirdPage">
            <div className="card shadow-lg dark:bg-base-200" id="card3">
              <div className="card-body">
                <h2 className="card-title">
                  {t('latest')}
                </h2>
                <div id="contact" className=" font-mono">
                  <ul>
                    {
                      [...data].map((item: postArray) => {
                        return (
                          <li key={item.key}>
                            <a href={item._url}>
                              <div id="title">
                                {item.title}
                                <br />
                                <div id="summary" className="text-xs flex" dangerouslySetInnerHTML={{ __html: item.summary }} />
                              </div>
                            </a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="forthPage">
            <div className="card shadow-lg dark:bg-base-200" id="card3">
              <div className="card-body">
                <h2 className="card-title">{t('contact')}</h2>
                <div id="contact" className="ml-20 mr-20 font-mono">
                  <div id="github" className="mt-5 h-5 flex text-zinc-500 dark:text-zinc-200">
                    <FontAwesomeIcon className="w-5 h-5 flex-none mr-2" icon={faGithub} />
                    <p className="h-5 flex-initial flex justify-center items-center">
                      <a href="https://github.com/robert1chi">GITHUB</a>
                    </p>
                  </div>
                  <div id="telegram" className="mt-5 h-5 flex text-zinc-500 dark:text-zinc-200">
                    <FontAwesomeIcon className="w-5 h-5 flex-none mr-2" icon={faTelegram} />
                    <p className="h-5 flex-initial flex justify-center items-center">
                      <a href="https://t.me/picoaoi_bot">TELEGRAM</a>
                    </p>
                  </div>
                  <div id="twitter" className="mt-5 h-5 flex text-zinc-500 dark:text-zinc-200">
                    <FontAwesomeIcon className="w-5 h-5 flex-none mr-2" icon={faTwitter} />
                    <p className="h-5 flex-initial flex justify-center items-center">
                      <a href="https://twitter.com/robert1chi">TWITTER</a>
                    </p>
                  </div>
                  <div id="email" className="mt-5 h-5 flex text-zinc-500 dark:text-zinc-200">
                    <FontAwesomeIcon className="w-5 h-5 flex-none mr-2" icon={faEnvelope} />
                    <p className="h-5 flex-initial flex justify-center items-center">
                      {/*<a href="#my-modal">EMAIL</a>*/}
                      <label htmlFor="my-modal">EMAIL</label>
                      <input type="checkbox" id="my-modal" className="modal-toggle" />
                      <div className="modal">
                        <div className="modal-box">
                          <p className='text-zinc-900 dark:text-zinc-100'>contactus#aoione.xyz(change the # to @)</p>
                          <div className="modal-action">
                            <label htmlFor="my-modal" className="btn btn-info dark:btn-primary">{t('close')}</label>
                          </div>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Top wy={window.scrollY} ch={clientHeight}/>
      <footer className="px-10 py-4 border-t footer bg-base-200 text-base-content border-base-300">
        <div className="h-[24px] items-center grid-flow-col">
          <p>© {new Date().getFullYear()} PicoAoi. All rights reserved.</p>
        </div>
        <div className="md:place-self-center md:justify-self-end">
          <div id="footerReact">
            <a className="flex flex-row items-center" href="https://reactjs.org/">
              <p>{t('powered')}</p>
              <FontAwesomeIcon className="w-[24px] h-[24px] flex-none ml-2" icon={faReact} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;


