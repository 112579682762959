import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';

const Change = () => {
    const { t, i18n } = useTranslation();
    const changeToLan = (arg: string) => {
        i18n.changeLanguage(arg);
      }
    return (
        <div className='dropdown dropdown-end'>
            <label className='btn btn-ghost normal-case text-xl' tabIndex={0}>
                <FontAwesomeIcon icon={faLanguage} className="h-5 w-5"/>
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 shadow bg-base-100 rounded-box w-40 text-black dark:text-white dark:bg-base-200">
                <li className={`${t('borderEN')}`}>
                    <a onClick={() => changeToLan("en")}>English</a>
                </li>
                <li className={`${t('borderCN')}`}>
                    <a onClick={() => changeToLan("zh")}>中文</a>
                </li>
                <li className={`${t('borderJA')}`}>
                    <a onClick={() => changeToLan("ja")}>日本語</a>
                </li>
            </ul>
        </div>)
}
export default Change;

/*
export const Dark = () => {
    return (
        <>
        </>
    )
}
*/